<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>
</template>

<script>
export default {
  mounted(){

      let gtmScript = document.createElement('script')
      gtmScript.setAttribute('src', 'https://gtm.nunwe.dev/gtag/js?id=GTM-PGV7VXJ')
      gtmScript.async = true
      document.head.appendChild(gtmScript)

      window.dataLayer = window.dataLayer || []
      window.gtag = function(){
        window.dataLayer.push(arguments);
      }

      window.gtag('js', new Date());

      window.gtag('config', 'G-PGV7VXJ');

      let gtmNoScript = document.createElement('iframe')
      gtmNoScript.src = "https://gtm.nunwe.dev/ns.html?id=GTM-PGV7VXJ"
      gtmNoScript.height = 0
      gtmNoScript.width = 0
      gtmNoScript.style.display = "none"
      gtmNoScript.style.visibility = "hidden"

      document.body.prepend(document.createElement('noscript').appendChild(gtmNoScript))
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
