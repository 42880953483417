import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// import { rudderanalytics } from "./scripts/rudder"
// <!-- Global site tag (gtag.js) - Google Analytics -->
// <script async src="https://<DOMAIN NAME>/gtag/js?id=<G-XXXXXX>"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', '<G-XXXXXX>');
// </script>

createApp(App).use(router).mount('#app')
// rudderanalytics.identify(
//     "123456",
//     { email: "name@domain.com" },
//     {
//       page: {
//         path: "",
//         referrer: "",
//         search: "",
//         title: "",
//         url: "",
//       }
//     },
//     () => {
//       console.log("in identify call");
//     }
//   );

//   rudderanalytics.track({
//     userId: '123456',
//     event: 'Item Viewed',
//     properties: {
//       revenue: 19.95,
//       shippingMethod: 'Premium'
//     }
//   },
//   () => {
//     console.log("in track call");
//   });